import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Loadable from "@loadable/component"

import Fade from "react-reveal/Fade"
import WorksItem from "./WorksItem"

const LoadableIframeVideo = Loadable(() => import("./IframeVideo"))

const WhatsNew = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOPUS: file(relativePath: { eq: "work-item-opus.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMakingJapanese: file(
        relativePath: { eq: "work-item-makingjapanese.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageYamaonna: file(relativePath: { eq: "work-item-yamaonna.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageBlackboxdiaries: file(
        relativePath: { eq: "work-item-blackboxdiaries.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageBlackboxdiariesModal: file(
        relativePath: { eq: "work-modal-blackboxdiaries.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOpusPoster: file(
        relativePath: { eq: "work-modal-opus-poster.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMakingJapanesePoster: file(
        relativePath: { eq: "work-modal-makingjapanese-poster.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageYamaonnaPoster: file(
        relativePath: { eq: "work-modal-yamaonna-poster.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageBlackboxdiariesPoster: file(
        relativePath: { eq: "work-modal-blackboxdiaries-poster.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const imageOPUS = data.imageOPUS.childImageSharp.fluid
  const imageMakingJapanese = data.imageMakingJapanese.childImageSharp.fluid
  const imageYamaonna = data.imageYamaonna.childImageSharp.fluid
  const imageBlackboxdiaries = data.imageBlackboxdiaries.childImageSharp.fluid
  const imageOpusPoster = data.imageOpusPoster.childImageSharp.fluid
  // const imageBlackboxdiariesPoster = data.imageBlackboxdiariesPoster.childImageSharp.fluid
  const imageBlackboxdiariesModal =
    data.imageBlackboxdiariesModal.childImageSharp.fluid
  const imageMakingJapanesePoster =
    data.imageMakingJapanesePoster.childImageSharp.fluid
  const imageYamaonnaPoster = data.imageYamaonnaPoster.childImageSharp.fluid

  const num = []
  for (let i = 0; i < 4; ++i) {
    num.push(i * 100 + 300)
  }
  return (
    <>
      <Fade bottom distance={"20px"} duration={400} delay={300}></Fade>
      <div className="works__items works__items--whatsnew">
        <WorksItem
          worksName="BLACK BOX DIARIES"
          worksYear="2024"
          worksDirector="Dir. Shiori Ito"
          worksImage={imageBlackboxdiaries}
          worksNum={num[0]}
          worksSlug="blackbox"
          worksContent={
            <>
              <Img
                className="modal__hero"
                fluid={imageBlackboxdiariesModal}
                alt="Black Box Diaries"
              />

              <div className="modal__story">
                <p>
                  BLACK BOX DIARIES follows director and journalist Shiori Ito’s
                  courageous investigation of her own sexual assault in an
                  improbable attempt to prosecute her high-profile offender.
                  Unfolding like a thriller and combining secret investigative
                  recordings, vérité shooting and emotional first-person video,
                  Shiori's quest becomes a landmark case in Japan, exposing the
                  country’s desperately outdated judicial and societal systems.
                </p>
              </div>
              <div className="modal__caption">
                {/* <Img
                  className="modal__poster"
                  fluid={imageBlackboxdiariesPoster}
                  alt="BLACK BOX DIARIES Poster"
                /> */}
                <div>
                  <p>
                    Directed by Shiori Ito
                    <br />
                    Documentary Feature / Japan - UK - USA / 103 minutes
                  </p>

                  <p>
                    Produced by Eric Nyari, Hanna Aqvilin, Shiori Ito
                    <br />
                    Edited and Co-Produced by Ema Ryan Yamazaki
                    <br />
                    Colorist Fumiro Sato
                    <br />
                    A Production by Hanashi Films, Cineric Creative, Star Sands
                    <br />
                    World Sales by Dogwoof
                  </p>

                  <p>
                    Sundance World Cinema Documentary Competition ‘24
                    <br />
                    MoMA Documentary Fortnight ‘24 (Closing Film)
                    <br />
                    SXSW ‘24
                    <br />
                    CPH: DOX ‘24
                  </p>
                </div>
              </div>
            </>
          }
        />
        <WorksItem
          worksName="THE MAKING OF A JAPANESE"
          worksYear="2023"
          worksDirector="Dir. Ema Ryan Yamazaki"
          worksImage={imageMakingJapanese}
          worksNum={num[1]}
          worksSlug="japanese"
          worksContent={
            <>
              <div className="modal__iframe__wrapper">
                <LoadableIframeVideo
                  videoSrc="https://player.vimeo.com/video/854281218?h=dbb4cb77e0"
                  title="THE MAKING OF A JAPANESE  - Trailer"
                />
              </div>

              <div className="modal__story">
                <p>
                  Located in one of Tokyo’s sprawling suburbs, Tsukado is one of
                  the largest public elementary schools in Japan with nearly
                  1,000 students. In the nation’s unique educational system,
                  children are tasked to run their own school in order to teach
                  communal values and how to play one’s role in the group.
                  Intimately capturing one school year from the perspective of
                  1st and 6th graders, THE MAKING OF A JAPANESE has the magic of
                  childhood with precious moments of joy, tears, and discovery —
                  as they learn the traits necessary to become part of Japanese
                  society.
                </p>
              </div>

              <div className="modal__caption">
                <Img
                  className="modal__poster"
                  fluid={imageMakingJapanesePoster}
                  alt="THE MAKING OF A JAPANESE"
                />
                <div>
                  <p>
                    Directed and Edited by Ema Ryan Yamazaki
                    <br />
                    Documentary Feature / Japan - USA - France - Finland / 99
                    min
                  </p>

                  <p>
                    Produced by Eric Nyari
                    <br />
                    Colorist Fumiro Sato
                    <br />
                    CINERIC CREATIVE and NHK present
                    <br />
                    In co-production with PYSTYMETSÄ and POINT DU JOUR
                    <br />
                    With the participation of YLE and FRANCE TÉLÉVISIONS
                    <br />
                    World Sales by Autlook
                  </p>

                  <p>
                    Tokyo International Film Festival ‘23
                    <br />
                    Thessaloniki International Documentary Festival ‘24
                    <br />
                    Best Pitch Award Tokyo Docs, pitched at Hot Docs Forum and
                    DocedgeKolkata
                  </p>
                </div>
              </div>
            </>
          }
        />
        <WorksItem
          worksName="Mountain Woman"
          worksYear="2022"
          worksDirector="Dir. Takeshi Fukunaga"
          worksImage={imageYamaonna}
          worksNum={num[2]}
          worksSlug="yamaonna"
          worksContent={
            <>
              <div className="modal__iframe__wrapper">
                <LoadableIframeVideo
                  videoSrc="https://www.youtube.com/embed/A27ZW9SpWwY"
                  title="Mountain Woman - trailer"
                />
              </div>

              <div className="modal__story">
                <p>
                  In a village in northeastern Japan in the late 18th century
                  struck by an unprecedented famine, Rin, the daughter of a
                  family shoulders the sins of their predecessors, lives a
                  robust life despite the scorn of the village people. One day,
                  Rin's father, Ihee, causes an incident that shakes the entire
                  village. Taking blame for her father’s crime, Rin leaves the
                  village and ventures deep into the forbidden mountains. There,
                  she meets a legendary and feared "mountain man," and her
                  destiny is set in motion. YAMA ONNA is a one-of-a-kind story
                  inspired by The Legends of Tono.
                </p>
              </div>

              <div className="modal__caption">
                <Img
                  className="modal__poster"
                  fluid={imageYamaonnaPoster}
                  alt="Mountain Woman"
                />
                <div>
                  <p>
                    Directed by Takeshi Fukunaga
                    <br />
                    Narrative Feature / Japan - USA / 98 minutes
                  </p>

                  <p>
                    Starring Anna Yamada, Masatoshi Nagase, Mirai Moriyama,
                    Ryutaro Ninomiya, Toko Miura
                    <br />
                    Produced by Eric Nyari, Harue Miyake, Mio Ietomi
                    <br />
                    Colorist and VFX Artist Fumiro Sato
                    <br />
                    A Production by Cineric Creative, Booster Project
                    <br />
                    International Co-Production with NHK
                  </p>

                  <p>
                    Tokyo International Film Festival In Competition ‘22
                    <br />
                    Hong Kong International Film Festival ‘23
                    <br />
                    Karlovy Vary International Film Festival ‘23
                    <br />
                    TAMA Film Awards Best New Director (Takeshi Fukunaga) and
                    Best New Actress (Anna Yamada)
                  </p>
                </div>
              </div>
            </>
          }
        />
        <WorksItem
          worksName="RYUICHI SAKAMOTO | OPUS"
          worksYear="2023"
          worksDirector="Dir. Neo Sora"
          worksImage={imageOPUS}
          worksNum={num[3]}
          worksSlug="opus"
          worksContent={
            <>
              <div className="modal__iframe__wrapper">
                <LoadableIframeVideo
                  videoSrc="https://www.youtube.com/embed/OyhTmH92Ljc"
                  title="RYUICHI SAKAMOTO | OPUS - Official US Trailer"
                />
              </div>

              <div className="modal__story">
                <p>
                  A celebration of an artist’s life in the purest sense, Ryuichi
                  Sakamoto | Opus is the definitive swan song of one of the
                  world’s greatest musicians. In late 2022, as a parting gift,
                  Ryuichi Sakamoto mustered all of his energy to leave us with
                  one final performance: a concert film featuring just him and
                  his piano. Curated and sequenced by Sakamoto himself, the
                  twenty pieces featured in the film wordlessly narrate his life
                  through his wide-ranging oeuvre. The selection spans his
                  entire career, from his pop-star period with Yellow Magic
                  Orchestra and his magnificent scores for filmmaker Bernardo
                  Bertolucci to his meditative final album,12. Intimately filmed
                  in a space he knew well and surrounded by his most trusted
                  collaborators, including director Neo Sora, his son, Sakamoto
                  bares his soul through his exquisitely haunting melodies,
                  knowing this was the last time he would be able to present his
                  art.
                </p>
              </div>
              <div className="modal__caption">
                <Img
                  className="modal__poster"
                  fluid={imageOpusPoster}
                  alt="OPUS"
                />
                <div>
                  <p>
                    Music Composed and Performed by Ryuichi Sakamoto
                    <br />
                    Documentary Feature / 103 minutes
                    <br />
                    Directed by Neo Sora
                    <br />
                    Produced by Norika Sora, Albert Tholen, Aiko Masubuchi, Eric
                    Nyari
                    <br />
                    Executive Producer Jeremy Thomas
                    <br />
                    Cinematography by Bill Kirstein
                  </p>

                  <p>
                    Venice International Film Festival ‘23
                    <br />
                    New York Film Festival ‘23
                    <br />
                    Yamagata International Documentary Film Festival ‘23
                    (Opening Film)
                    <br />
                    Busan International Film Festival ‘23
                    <br />
                    BFI London Film Festival ‘23
                    <br />
                    USA distribution by Janus/Criterion
                  </p>
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  )
}

export default WhatsNew
