import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Loadable from "@loadable/component"

import Fade from "react-reveal/Fade"
import WorksItem from "./WorksItem"

const LoadableIframeVideo = Loadable(() => import("./IframeVideo"))

const Works = () => {
  const data = useStaticQuery(graphql`
    query {
      imageAinu: file(relativePath: { eq: "work-item-ainu.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMonte: file(relativePath: { eq: "work-item-monte.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageShell: file(relativePath: { eq: "work-item-shellcollector.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageCut: file(relativePath: { eq: "work-item-cut.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOdoriko: file(relativePath: { eq: "work-item-odoriko.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageKoshien: file(relativePath: { eq: "work-item-koshien.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageAsync: file(relativePath: { eq: "work-item-async.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageCoda: file(relativePath: { eq: "work-item-coda.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMonkey: file(relativePath: { eq: "work-item-monkey.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageAinuModal: file(relativePath: { eq: "work-modal-ainu.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageBlackboxdiariesModal: file(
        relativePath: { eq: "work-modal-blackboxdiaries.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOdorikoModal: file(relativePath: { eq: "work-modal-odoriko.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 380) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageAinuPoster: file(
        relativePath: { eq: "work-modal-ainu-poster.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMonkeyPoster: file(
        relativePath: { eq: "work-modal-monkey-poster.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageAsyncPoster: file(
        relativePath: { eq: "work-modal-async-poster.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageCodaPoster: file(
        relativePath: { eq: "work-modal-coda-poster.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageCutPoster: file(relativePath: { eq: "work-modal-cut-poster.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOdorikoPoster: file(
        relativePath: { eq: "work-modal-odoriko-poster.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageKoshienPoster: file(
        relativePath: { eq: "work-modal-koshien-poster.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMontePoster: file(
        relativePath: { eq: "work-modal-monte-poster.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageShellPoster: file(
        relativePath: { eq: "work-modal-shell-poster.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const imageAinu = data.imageAinu.childImageSharp.fluid
  const imageMonte = data.imageMonte.childImageSharp.fluid
  const imageShell = data.imageShell.childImageSharp.fluid
  const imageCut = data.imageCut.childImageSharp.fluid
  const imageOdoriko = data.imageOdoriko.childImageSharp.fluid
  const imageKoshien = data.imageKoshien.childImageSharp.fluid
  // const imageAsync = data.imageAsync.childImageSharp.fluid
  const imageCoda = data.imageCoda.childImageSharp.fluid
  const imageMonkey = data.imageMonkey.childImageSharp.fluid
  // const imageAinuModal = data.imageAinuModal.childImageSharp.fluid
  const imageOdorikoModal = data.imageOdorikoModal.childImageSharp.fluid
  const imageAinuPoster = data.imageAinuPoster.childImageSharp.fluid
  const imageMonkeyPoster = data.imageMonkeyPoster.childImageSharp.fluid
  // const imageAsyncPoster = data.imageAsyncPoster.childImageSharp.fluid
  const imageCodaPoster = data.imageCodaPoster.childImageSharp.fluid
  const imageCutPoster = data.imageCutPoster.childImageSharp.fluid
  const imageOdorikoPoster = data.imageOdorikoPoster.childImageSharp.fluid
  const imageKoshienPoster = data.imageKoshienPoster.childImageSharp.fluid
  const imageMontePoster = data.imageMontePoster.childImageSharp.fluid
  const imageShellPoster = data.imageShellPoster.childImageSharp.fluid

  const num = []
  for (let i = 0; i < 8; ++i) {
    num.push(i * 100 + 300)
  }
  return (
    <>
      <Fade bottom distance={"20px"} duration={400} delay={300}>
        <h3 className="works__header">Narrative Films</h3>
      </Fade>
      <div className="works__items">
        <WorksItem
          worksName="Ainu Mosir"
          worksYear="2020"
          worksDirector="Dir. Takeshi Fukunaga"
          worksImage={imageAinu}
          worksNum={num[0]}
          worksSlug="ainu"
          worksContent={
            <>
              <div className="modal__iframe__wrapper">
                <LoadableIframeVideo
                  videoSrc="https://www.youtube.com/embed/jcqgGaoQerc"
                  title="ARRAY's AINU MOSIR Written and Directed by Takeshi Fukunaga"
                />
              </div>

              <div className="modal__story">
                <p>
                  Kanto is a fourteen year-old boy and a descendent of Japan’s
                  indigenous Ainu people. He lives with his mother, Emi (43),
                  who runs a souvenir store in a small town in Hokkaido where
                  Ainu people embrace tourism to keep the culture alive and to
                  create jobs.
                </p>
                <p>
                  Kanto has been struggling with the loss of his father a year
                  ago. The radical Ainu community leader, Debo (56), tells Kanto
                  about the small hole in the cliff of the nearby forest that
                  Ainu people have long believed to be a path to the other side
                  of the world — where dead people live. Kanto decides to visit
                  the hole, hoping to see his deceased father.
                </p>
              </div>
              <div className="modal__caption">
                <Img
                  className="modal__poster"
                  fluid={imageAinuPoster}
                  alt="Ainu Mosir"
                />
                <div>
                  <p>
                    Written and Directed by Takeshi Fukunaga
                    <br />
                    Narrative Feature / USA - Japan - China / 84 min
                  </p>

                  <p>
                    Special Jury Mention award, Tribeca Film Festival '20
                    <br />
                    Best Film, Guanajuato International Film Festival '20
                    <br />
                    Released in theaters in Japan from fall 2020
                    <br />
                    Acquired by{" "}
                    <a
                      href="https://www.arraynow.com/ainu-mosir"
                      target="_blank"
                      rel="noopener noreferrer"
                      className=" link-color link-ul"
                    >
                      Ava Duvernay’s ARRAY
                    </a>{" "}
                    and released on Netflix in USA/Canada/UK/Australia/New
                    Zealand
                  </p>

                  <p>
                    Starring Kanto Shimokura, Emi Shimokura, and Debo Akibe,
                    with Toko Miura and Lily Franky
                    <br />
                    Produced by Eric Nyari, Harue Miyake
                    <br />
                    Director of Photography Sean Price Williams
                    <br />
                    An International Co-Production by Cineric Creative (USA) and
                    Booster Project (Japan), In Co-production with GIO {"&"} VIS
                    FILM (China)
                  </p>
                </div>
              </div>
            </>
          }
        />
        <WorksItem
          worksName="Monte"
          worksYear="2016"
          worksDirector="Dir. Amir Naderi"
          worksImage={imageMonte}
          worksNum={num[1]}
          worksSlug="monte"
          worksContent={
            <>
              <div className="modal__iframe__wrapper">
                <LoadableIframeVideo
                  videoSrc="https://www.youtube.com/embed/n99Iga8zP5I"
                  title="Monte di Amir Naderi | TRAILER"
                />
              </div>

              <div className="modal__story">
                <p>
                  Late Middle Ages. Agostino lives in poverty with his wife Nina
                  and son Giovanni outside a small village at the foot of the
                  mountains. An immense mountain rises like a wall, stopping the
                  sun’s rays and thus reducing their farmland to stones and
                  twigs. Despite all suggestions to move away for a better life,
                  Agostino insists his family’s fate lies there between the
                  peaks. He firmly believes that a man’s roots cannot betray
                  him. Agostino struggles in vain to find a solution to better
                  provide for his starving family. But their lives do not
                  improve, and Agostino decides to challenge the ancient
                  mountain’s immensity and power.
                </p>
              </div>

              <div className="modal__caption">
                <Img
                  className="modal__poster"
                  fluid={imageMontePoster}
                  alt="Monte"
                />
                <div>
                  <p>
                    Written, Directed, and Edited by Amir Naderi
                    <br />
                    Narrative Feature / Italy - USA - France / 107 min
                  </p>

                  <p>
                    Premiered at the 2016 Venice Film Festival with Naderi
                    receiving the Glory to the Filmmaker Award
                  </p>

                  <p>
                    Starring Andreas Sartoretti, Claudia Potenza
                    <br />
                    Director of Cinematography Roberto Cimatti
                    <br />
                    Sound Editor/Assistant Editor/VFX Artist Fumiro Sato
                    <br />
                    Produced by Carlo Hintermann, Gerardo Pannichi, Rino
                    Sciarretta, and Eric Nyari
                    <br />
                    An International Co-Production by Citrullo International and
                    Zivago Media (Italy), Cineric, Inc. (USA), Cine-sud
                    Promotion and KNM (France)
                  </p>
                </div>
              </div>
            </>
          }
        />
        <WorksItem
          worksName="The Shell Collector"
          worksYear="2016"
          worksDirector="Dir. Yoshifumi Tsubota"
          worksImage={imageShell}
          worksNum={num[2]}
          worksSlug="shell"
          worksContent={
            <>
              <div className="modal__iframe__wrapper">
                <LoadableIframeVideo
                  videoSrc="https://www.youtube.com/embed/6gFf3CthhIM"
                  title="The Shell Collector - trailer"
                />
              </div>

              <div className="modal__story">
                <p>
                  A blind old man lives in solitary retirement, studying shells
                  on a remote island in Okinawa. One day an estranged painter
                  comes to his island. She is mysteriously cured of her rare
                  disease by the sting of a poisonous shellfish…{" "}
                </p>
              </div>

              <div className="modal__caption">
                <Img
                  className="modal__poster"
                  fluid={imageShellPoster}
                  alt="The Shell Collector"
                />
                <div>
                  <p>
                    Directed by Yoshifumi Tsubota
                    <br />
                    Narrative Feature / USA - Japan / 86 min
                  </p>

                  <p>
                    45th International Film Festival Rotterdam, Bright Future
                    Competition
                    <br />
                    10th Japan Cuts Centerpiece Screening, Cut Above Award to
                    Lily Franky
                  </p>

                  <p>
                    Based on the short story by Pulitzer Prize winner Anthony
                    Doerr
                    <br />
                    Starring Lily Franky, Shinobu Terajima, Sosuke Ikematsu, Ai
                    Hashimoto
                    <br />
                    Written by Kaori Sawai, Yoshifumi Tsubota
                    <br />
                    Producers Eric Nyari, Hisami Kuroiwa, Yuji Sadai
                    <br />
                    Directory of Photography Akiko Ashizawa
                    <br />
                    Edited by Keiko Deguchi
                    <br />
                    Music by Billy Martin
                    <br />
                    Abstract Image Director Takashi Makino
                    <br />
                    An International Co-Production by Bitters End, Media Space,
                    and Cineric Creative
                  </p>
                </div>
              </div>
            </>
          }
        />
        <WorksItem
          worksName="CUT"
          worksYear="2011"
          worksDirector="Dir. Amir Naderi"
          worksImage={imageCut}
          worksNum={num[3]}
          worksSlug="cut"
          worksContent={
            <>
              <div className="modal__iframe__wrapper">
                <LoadableIframeVideo
                  videoSrc="https://www.youtube.com/embed/nLF0KU5iNYM"
                  title="CUT TRAILER"
                />
              </div>

              <div className="modal__story">
                <p>
                  Shuji is an uncompromising young filmmaker at odds with
                  Japanese society. One day he learns that his loan shark
                  brother, who had helped to finance his films, has been
                  executed by his own yakuza gang for failing to repay his
                  debts. Described as a love poem to Japanese films of the past,
                  as well as a protest at the present, CUT is an exploration of
                  one man's obsessive relationship with cinema.
                </p>
              </div>
              <div className="modal__caption">
                <Img
                  className="modal__poster"
                  fluid={imageCutPoster}
                  alt="CUT"
                />
                <div>
                  <p>
                    Written, Directed, and Edited by Amir Naderi
                    <br />
                    Narrative Feature / Japan / 133 min
                  </p>

                  <p>
                    68th Venice International Film Festival Orrizonti Section
                    Opening Film <br />
                    Toronto, Busan, Tokyo FILMeX, Tribeca, Karlovy Vary Official
                    Selection
                    <br />
                    Japan Professional Film Awards Best Director (Amir Naderi),
                    Best Actor (Hidetoshi Nishijima)
                  </p>

                  <p>
                    Starring Hidetoshi Nishijima, Takako Tokiwa, Takashi Sasano,
                    Shun Sugata, Denden
                    <br />
                    Co-writers Shinji Aoyama, Yuichi Tazawa
                    <br />
                    Produced by Eric Nyari, Engin Yenidunya, Regis Arnaud
                    <br />
                    Producers Yuji Sadai, Shohreh Golparian
                    <br />
                    A Production of Tokyo Story In Association with Bitters End
                    <br />
                    Supported by Busan International Film Festival — Asian
                    Cinema Fund
                    <br />
                    Post Production Support by Cineric, Inc.
                  </p>
                </div>
              </div>
            </>
          }
        />
      </div>
      <Fade bottom distance={"20px"} duration={400} delay={300}>
        <h3 className="works__header">Documentaries</h3>
      </Fade>
      <div className="works__items works__items--documentaries">
        <WorksItem
          worksName="ODORIKO"
          worksYear="2020"
          worksDirector="Dir. Yuichiro Okutani"
          worksImage={imageOdoriko}
          worksNum={num[4]}
          worksSlug="odoriko"
          worksContent={
            <>
              <Img
                className="modal__hero"
                fluid={imageOdorikoModal}
                alt="Odoriko"
              />

              <div className="modal__story">
                <p>
                  Odoriko—dancers at strip theaters—travel across Japan with
                  their costume cases in hand, sleeping in their dressing rooms
                  in order to perform, and moving on to a new place every ten
                  days. The world of strip theater, brimming with a nostalgic
                  Showa era (1926–1989) air, has mostly lost its audience, with
                  the number of theaters continuing to decline, but as these
                  women devote themselves night and day to their craft, its
                  splendor fleetingly returns in the stage performances they
                  create. The true faces that the odoriko show in the stage
                  wings, their everyday lives in the dressing rooms, the
                  thoughts they commit to striptease, their love for their
                  families—through this film record, these are all the
                  irreplaceable treasures of the moment.
                </p>
              </div>

              <div className="modal__caption">
                <Img
                  className="modal__poster"
                  fluid={imageOdorikoPoster}
                  alt=""
                />
                <div>
                  <p>
                    Directed by Yuichiro Okutani
                    <br />
                    Documentary Feature / Japan - USA - France / 114 minutes
                    <br />
                    Produced by Asako Fujioka, Eric Nyari, Yuichiro Okutani
                    <br />
                    A Production by Documentary Dream Center, Cineric Creative
                    <br />
                    VFX Artist Fumiro Sato
                    <br />
                    Colorist Fernanda Gurgel
                  </p>
                  <p>
                    IDFA In-Competition ‘20
                    <br />
                    Cinéma du Réel Winner SCAM Award and Heritage Award ‘21
                  </p>
                </div>
              </div>
            </>
          }
        />

        <WorksItem
          worksName="Koshien: Japan’s Field of Dreams"
          worksYear="2019"
          worksDirector="Dir. Ema Ryan Yamazaki"
          worksImage={imageKoshien}
          worksNum={num[5]}
          worksSlug="koshien"
          worksContent={
            <>
              <div className="modal__iframe__wrapper">
                <LoadableIframeVideo
                  videoSrc="https://player.vimeo.com/video/366753473"
                  title="Koshien: Japan’s Field of Dreams"
                />
              </div>

              <div className="modal__story">
                <p>
                  Baseball is life for the die-hard competitors in Koshien,
                  Japan’s national high school baseball championship, whose
                  alumni include US baseball star Shohei Ohtani and former
                  Yankee Hideki Matsui. As popular as America’s World Series,
                  the stakes are beyond high in this single-elimination
                  tournament. However, for Coach Mizutani, cleaning the grounds
                  and greeting guests are equally important as honing baseball
                  skills, demonstrating discipline, sacrifice and unwavering
                  dedication. This documentary follows Mizutani and his team on
                  their quest to win the 100th annual Koshien, and, in the
                  process, goes beyond baseball to reveal the heart of the
                  Japanese national character.
                </p>
              </div>

              <div className="modal__caption">
                <Img
                  className="modal__poster"
                  fluid={imageKoshienPoster}
                  alt=""
                />
                <div>
                  <p>
                    Directed and Edited by Ema Ryan Yamazaki
                    <br />
                    Documentary Feature / USA - Japan / 94 min
                    <br />
                    World Premiere at DOC NYC ’19
                    <br />
                    Broadcast in Primetime on ESPN in June 2020
                    <br />
                    Theatrical release in Japan from August 2020
                    <br />
                    Distributed by{" "}
                    <a
                      href="https://firstrunfeatures.com/koshien.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-color link-ul"
                    >
                      First Run Features
                    </a>
                    <br />
                    Streamed on The Criterion Channel, a{" "}
                    <a
                      href="https://www.nytimes.com/2021/09/24/movies/international-streaming.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-color link-ul"
                    >
                      New York Times recommendation
                    </a>
                  </p>
                  <p>
                    Starring Tetsuya Mizutani, Hiroshi Sasaki, Shohei Ohtani,
                    Yusei Kikuchi
                    <br />
                    Produced by Eric Nyari
                    <br />
                    Cinematography by Michael Crommett
                    <br />
                    International Co-production by Cineric Creative, NHK, and
                    NHK Enterprises
                    <br />
                    Special Cooperation by The Japanese High School Baseball
                    Federation and The Asahi Shimbun Company
                    <br />
                    In Association with Major League Baseball
                  </p>
                </div>
              </div>
            </>
          }
        />
        {/* <WorksItem
          worksName="RYUICHI SAKAMOTO: async AT THE PARK AVENUE ARMORY"
          worksYear="2018"
          worksDirector="Dir. Stephen Schible"
          worksImage={imageAsync}
          worksNum={num[5]}
          worksSlug="async"
          worksContent={
            <>
              <div className="modal__iframe__wrapper">
                <LoadableIframeVideo
                  videoSrc="https://www.youtube.com/embed/XO_7PMMua94"
                  title="RYUICHI SAKAMOTO: async AT THE PARK AVENUE ARMORY | Official Trailer"
                />
              </div>

              <div className="modal__story">
                <p>
                  In April 2017 Japanese composer, pianist and music producer
                  Ryūichi Sakamoto made a guest appearance for two evenings in
                  the Veteran’s Room, an intimate, 200-seater hall at the Park
                  Avenue Armory in New York. Stephen Nomura Schible recorded
                  this concert with his camera. His film shows the award-winning
                  film composer, whose unusual soundtracks have left their mark
                  on dramas such as The Last Emperor and The Revenant, inventing
                  his spontaneous ‘asynchronous’ music. We see him in action
                  with various electronic instruments, a grand piano without a
                  lid, a guitar and a glass panel. The resulting chords sound
                  like a cosmic, angelic chorale. In addition, a huge screen
                  mounted on the ceiling of the room above the grand piano
                  translates the melancholic, contemplative sounds that float
                  into the air into black-and-white images, engendering the kind
                  of free-fall associations reminiscent of early avant-garde
                  film. Sakamoto’s New York concert was interpreted by critics
                  as an homage to life itself.
                </p>
              </div>

              <div className="modal__caption">
                <Img
                  className="modal__poster"
                  fluid={imageAsyncPoster}
                  alt="async"
                />
                <div>
                  <p>
                    Directed by Stephen Nomura Schible
                    <br />
                    Documentary Feature / USA - Japan / 65 min
                  </p>

                  <p>
                    68th Berlin International Film Festival, Berlinale Special
                  </p>

                  <p>
                    KAB America Inc. Presents
                    <br />
                    A Cineric / Borderland Media Production
                    <br />
                    Executive Producer Norika Sora
                    <br />
                    Produced by Stephen Nomura Schible, Eric Nyari, Yoshiko
                    Hashimoto
                    <br />
                    Directors of Photography Tom Richmond, Neo S. Sora
                    <br />
                    Editor Hisayo Kushida
                    <br />
                    Production Support by Kadokawa, Avex Digital, Dentsu Music
                    and Entertainment, NHK
                    <br />
                    Visual Post Production by Cineric, Inc.
                  </p>
                </div>
              </div>
            </>
          }
        /> */}
        <WorksItem
          worksName="Ryuichi Sakamoto: Coda"
          worksYear="2017"
          worksDirector="Dir. Stephen Schible"
          worksImage={imageCoda}
          worksNum={num[6]}
          worksSlug="coda"
          worksContent={
            <>
              <div className="modal__iframe__wrapper">
                <LoadableIframeVideo
                  videoSrc="https://www.youtube.com/embed/Fl-pKw5n0mI"
                  title="Ryuichi Sakamoto: Coda | Official Trailer"
                />
              </div>

              <div className="modal__story">
                <p>
                  One of the most important artists of our era, Ryuichi
                  Sakamoto’s has had a prolific career spanning over four
                  decades, from techno-pop stardom to Oscar-winning music
                  composer. The evolution of his music has coincided with his
                  life journeys. After Fukushima, Sakamoto became an iconic
                  figure in Japan’s social movement against nuclear power. As
                  Sakamoto returns to music following a cancer, his haunting
                  awareness of life crisis leads to a resounding new
                  masterpiece. Ryuichi Sakamoto: Coda is an intimate portrait of
                  both the artist and the man.
                </p>
              </div>

              <div className="modal__caption">
                <Img
                  className="modal__poster"
                  fluid={imageCodaPoster}
                  alt="Coda"
                />
                <div>
                  <p>
                    Directed by Stephen Nomura Schible
                    <br />
                    Documentary Feature / USA - Japan / 102 min
                  </p>

                  <p>
                    Official Selection, Venice Film Festival '17
                    <br />
                    Samurai Film Award, Tokyo Film Film Festival '17
                    <br />
                    Hochi Film Award '17
                    <br />
                    Official Selection, Tribeca Film Festival '18
                    <br />
                    30th Japan Ministry of Culture Film Award
                    <br />
                    New York Times Critics Pick
                  </p>

                  <p>
                    Kadokawa, Avex Digital, & Dentsu Music and Entertainment
                    Present
                    <br />
                    A Cineric/Borderland Media Production
                    <br />
                    Produced by Stephen Nomura Schible, Eric Nyari
                    <br />
                    Executive Producers Tsuguhiko Kadokawa, Hisaou Wakaizumi,
                    Shuichi Machida, and Norika Sora
                    <br />
                    Directors of Photography Neo S. Sora and Tom Richmond
                    <br />
                    In Co-production with NHK, In Co-Production with AVROTROS
                    <br />
                    Production Support by Documentary Japan
                  </p>
                </div>
              </div>
            </>
          }
        />
        <WorksItem
          worksName="Monkey Business: The Adventures of Curious George’s Creators"
          worksYear="2017"
          worksDirector="Dir. Ema Ryan Yamazaki"
          worksImage={imageMonkey}
          worksNum={num[7]}
          worksSlug="monkey"
          worksContent={
            <>
              <div className="modal__iframe__wrapper">
                <LoadableIframeVideo
                  videoSrc="https://www.youtube.com/embed/Ayjx-0wZxpg"
                  title="Monkey Business: The Adventures of Curious George’s Creators (2017)"
                />
              </div>

              <div className="modal__story">
                <p>
                  Curious George is the most popular monkey in the world. Since
                  his introduction in the first publication in 1941, the beloved
                  series has sold over 75 million books in more than 25
                  languages. MONKEY BUSINESS explores the lesser-known tale of
                  George's creators, Hans and Margret Rey, who were German Jews,
                  global refugees, immigrants, artists, and Americans. On
                  makeshift bicycles with sketches of a curious little monkey
                  aboard, they rode across Nazi occupied Europe in search of a
                  freedom that would nurture one of the most treasured
                  children’s book series of all time. Using a creative mix of
                  animation, archival materials, and interviews, the film
                  reveals the authors to be just as adventurous and curious as
                  George himself.
                </p>
              </div>
              <div className="modal__caption">
                <Img
                  className="modal__poster"
                  fluid={imageMonkeyPoster}
                  alt="Monkey Business"
                />
                <div>
                  <p>
                    Directed, Produced, and Edited by Ema Ryan Yamazaki
                    <br />
                    Documentary Feature / USA / 82 min
                  </p>

                  <p>
                    World Premiere at LAFF ’17 Audience Award at Nantucket FF
                    ‘17
                    <br />
                    Focal Award Nomination ‘18
                    <br />
                    $186,000 raised on{" "}
                    <a
                      href="https://www.kickstarter.com/projects/1344946756/curious-george-documentary"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-color link-ul"
                    >
                      Kickstarter
                    </a>
                    <br />
                    Distributed worldwide by The Orchard
                  </p>

                  <p>
                    In Association with Cineric
                    <br />
                    Executive Producer Eric Nyari
                    <br />
                    Narrated by Sam Waterston
                    <br />
                    Animation by Jacob Kafka
                  </p>
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  )
}

export default Works
