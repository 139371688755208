import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../../components/Layout"
import Hero from "../../components/Hero"
import Works from "../../components/Works"
import WhatsNew from "../../components/WhatsNew"
import Services from "../../components/Services"
import RestorationImage from "../../components/RestorationImage"
import TeamItem from "../../components/TeamItem"
import Location from "../../components/Location"
import Logo from "../../components/Logo"

const IndexPage = ({ location }) => {
  const lang = "en"
  const pageSlug = "index-en"

  const data = useStaticQuery(graphql`
    query {
      servicesImageSmall: file(
        relativePath: { eq: "services-image-small.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      servicesImageMedium: file(
        relativePath: { eq: "services-image-medium.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      teamProfile1: file(relativePath: { eq: "team-profile1.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      teamProfile2: file(relativePath: { eq: "team-profile2.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      teamProfile3: file(relativePath: { eq: "team-profile3.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const servicesImage = [
    data.servicesImageSmall.childImageSharp.fluid,
    {
      ...data.servicesImageMedium.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  const teamProfile1 = data.teamProfile1.childImageSharp.fluid
  const teamProfile2 = data.teamProfile2.childImageSharp.fluid
  const teamProfile3 = data.teamProfile3.childImageSharp.fluid

  return (
    <Layout lang={lang} pageSlug={pageSlug} location={location}>
      <Hero />
      <section id="intro" className="intro">
        <div className="container-narrow">
          <div className="intro__content">
            <Fade bottom distance={"20px"} duration={400} delay={300}>
              <div className="intro__content--logo">
                <Logo />
              </div>
            </Fade>
            <Fade bottom distance={"20px"} duration={400} delay={500}>
              <div className="intro__content--description">
                <p>
                  <span className="em">Cineric Creative</span> is an independent
                  production and post-services company based in New York and
                  Tokyo. It is especially focused on international
                  co-productions with Japan, where it has a strong reputation
                  for helping to realize ambitious art-house films.
                  <br />
                  Cineric Creative also serves as an international
                  representative for Cineric Inc, the industry’s leading film
                  restoration and preservation house.
                </p>

                <p>
                  Besides co-productions, it offers the full suite of high-end
                  online post services, and produces new video content for
                  select clients. Cineric Creative also serves as an
                  international representative for Cineric Inc, the industry’s
                  leading film restoration and preservation house.
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <section id="whatsnew" className="works intersection">
        <div className="section__header__wrapper">
          <Fade bottom distance={"20px"} duration={400} delay={300}>
            <h2 className="section__header">WHAT'S NEW</h2>
          </Fade>
        </div>
        <WhatsNew />
      </section>

      <section id="works" className="works intersection">
        <div className="section__header__wrapper">
          <Fade bottom distance={"20px"} duration={400} delay={300}>
            <h2 className="section__header">FEATURED WORKS</h2>
          </Fade>
        </div>
        <Works />
      </section>

      <section id="services" className="services intersection">
        <div className="section__header__wrapper">
          <Fade bottom distance={"20px"} duration={400} delay={300}>
            <h2 className="section__header">SERVICES</h2>
          </Fade>
        </div>

        <div className="services__content">
          <div className="container-narrow">
            <Fade bottom distance={"20px"} duration={400} delay={300}>
              <div className="services__content__image">
                <Img fluid={servicesImage} alt="Services" />
              </div>
            </Fade>

            <Fade bottom distance={"20px"} duration={400} delay={300}>
              <div className="services__content__text">
                Using its state-of-the-art facilities, Cineric post spans the
                full suite of online services from high-end visual effects to
                color grading to opening/end titles to DCP and other digital
                mastering — supporting feature films, documentaries, TV
                commercials, and new and evolving forms of content. Besides
                commercial films, Cineric frequently collaborates with
                international auteur directors on artistically sensitive and
                often novel post workflows. It offers select clients planning
                and directing services for TV commercials and promotional
                videos. In addition, it provides local production services for
                projects with Japanese elements, including field producing,
                editing, trailer-making, translation, and subtitles.
              </div>
            </Fade>
          </div>
        </div>
        <Services />
      </section>

      <section id="restoration" className="restoration intersection">
        <div className="section__header__wrapper">
          <Fade bottom distance={"20px"} duration={400} delay={300}>
            <h2 className="section__header">RESTORATION</h2>
          </Fade>
        </div>
        <div className="restoration__content">
          <Fade bottom distance={"20px"} duration={400} delay={300}>
            <div className="restoration__content__text--description container-narrow">
              Cineric Creative serves as an international representative for
              Cineric Inc, the industry’s leading film restoration and
              preservation house. Founded in 1982 in New York and with a new
              facility in Lisbon, Cineric has pioneered many analog and digital
              technologies such as wet-gate scanning and 4K digital restoration
              on projects such as Dr. Strangelove, The King and I, and Olympia.
              Cineric’s Japanese 4K restorations include Kenji Mizoguchi’s
              Ugetsu and Yasujiro Ozu’s Late Spring.
            </div>
          </Fade>

          <RestorationImage />

          <Fade bottom distance={"20px"} duration={400} delay={300}>
            <div className="restoration__content__text--cineric container-narrow">
              LEARN MORE ABOUT CINERIC’S RESTORATION WORK IN{" "}
              <br className="medium-only" />
              NEW YORK AT{" "}
              <a
                href="http://cineric.com/"
                className="link-color link-ul"
                target="_blank"
                rel="noopener noreferrer"
              >
                cineric.com
              </a>{" "}
              AND LISBON AT{" "}
              <a
                href="https://www.cineric.pt/"
                className="link-color link-ul"
                target="_blank"
                rel="noopener noreferrer"
              >
                cineric.pt
              </a>
            </div>
          </Fade>
        </div>
      </section>

      <section id="team" className="team intersection">
        <div className="section__header__wrapper">
          <Fade bottom distance={"20px"} duration={400} delay={300}>
            <h2 className="section__header">TEAM</h2>
          </Fade>
        </div>
        <div className="team__content container">
          <div className="team__lists">
            <TeamItem
              image={teamProfile1}
              slug="1"
              name="Eric Nyari"
              num="1"
              title="President / Producer"
              detail={
                <>
                  <p>
                    Based in Tokyo and New York, Eric is President of Cineric
                    Creative and International Representative for renowned film
                    restoration house Cineric, Inc.
                  </p>
                  <p>
                    Eric has produced numerous films in Japan, including
                    director Amir Naderi's CUT, which was the Opening Film of
                    the 2011 Venice Film Festival’s Orizzonti section. He
                    produced MONTE, an Italy/USA/French co-production, which
                    premiered at the 2016 Venice Film Festival with Naderi
                    receiving the Glory to the Filmmaker Award. In documentary,
                    he produced the critically acclaimed portrait RYUICHI
                    SAKAMOTO: CODA, (Venice 2017) and companion concert film,
                    RYUICHI SAKAMOTO: ASYNC LIVE AT THE PARK AVENUE ARMORY
                    (Berlin 2018). Eric has managed 4K restorations of Japanese
                    classics such as Kenji Mizoguchi's UGETSU with Martin
                    Scorsese's Film Foundation, as well as Yasujiro Ozu's LATE
                    SPRING.
                  </p>
                  <p>
                    In recent years, he has produced Yoichiro Okutani’s ODORIKO,
                    which won two awards at Cinema du Reel in 2021; Takeshi
                    Fukunaga’s AINU MOSIR, which was awarded a Special Jury
                    Mention in Tribeca’s International Narrative Competition in
                    2020, and Fukunaga’s MOUNTAIN WOMAN (Tokyo International
                    2022); Ema Ryan Yamazaki's KOSHIEN: JAPAN’S FIELD OF DREAMS
                    (DOC NYC 2019). He has served as a Producer on director Neo
                    Sora's RYUICHI SAKAMOTO | OPUS, which premiered at the 2023
                    Venice and New York Film Festival, and Sora's upcoming
                    narrative debut, TREMOLO; Shiori Ito’s self-documentary
                    BLACK BOX DIARIES, which premiered to critical acclaim at
                    Sundance 2024.
                  </p>
                </>
              }
            />
            <TeamItem
              image={teamProfile2}
              slug="2"
              name="Ema Ryan Yamazaki"
              num="2"
              title="Director / Producer / Editor"
              detail={
                <>
                  <p>
                    Raised in Osaka, Japan, by a Japanese mother and British
                    father, Ema grew up navigating between Japanese and Western
                    cultures. She uses her unique storytelling perspective as
                    both an insider and outsider in Japan. Her third feature
                    documentary, THE MAKING OF A JAPANESE, premiered at the
                    Tokyo International Film Festival in 2023 and is currently
                    playing festivals and in distribution around the world. She
                    has also recently served as Editor and Co-Producer for
                    Shiori Ito's BLACK BOX DIARIES, which premiered to critical
                    acclaim at Sundance 2024.
                  </p>
                  <p>
                    Ema’s first feature documentary, MONKEY BUSINESS: THE
                    ADVENTURES OF CURIOUS GEORGE’S CREATORS (2017) was released
                    worldwide by The Orchard, after raising over $186,000 on
                    Kickstarter. It premiered at the LA Film Festival and won
                    the Audience Award at the Nantucket Film Festival.
                  </p>
                  <p>
                    KOSHIEN: JAPAN’S FIELD OF DREAMS (2019), Ema’s second
                    feature documentary about the phenomenon of high school
                    baseball in Japan, premiered at DOC NYC. The film aired in
                    primetime on ESPN and was released theatrically in Japan. It
                    later became a New York Times recommendation for
                    international films to stream, and was featured on the
                    Criterion Channel.
                  </p>
                  <p>
                    Ema's latest documentary feature, THE MAKING OF A JAPANESE,
                    follows one year in a Japanese public school, observing
                    children learn the traits necessary to become part of
                    Japanese society. The Japan/USA/France/Finland co-production
                    is represented by renowned sales agent Autlook.
                  </p>
                  <p>
                    Ema graduated from New York University, and began her career
                    as an editor, mentored by documentary mogul Sam Pollard. Ema
                    has served as Editor and Co-Producer for director Marc
                    Levin’s CLASS DIVIDE which won the Grand Jury Prize at DOC
                    NYC in 2015. She was Supervising Editor and Co-Director for
                    director Naomi Kawase’s official Olympic film for Tokyo
                    2020, which premiered at the Cannes Film Festival in 2022.
                  </p>
                </>
              }
            />
            <TeamItem
              image={teamProfile3}
              slug="3"
              name="Fumiro Sato"
              num="3"
              title="Director / VFX Artist"
              detail={
                <>
                  <p>
                    Born in Shimane, Japan, Fumiro moved to New York in 2017 to
                    join Cineric.
                  </p>
                  <p>
                    He is responsible for new post-production services, where he
                    closely works with directors as a VFX artist and online
                    editor, managing their full post workflow.
                  </p>
                  <p>
                    For TV commercial and promotional video content, he utilizes
                    his varied digital skills including as a director and Flame
                    artist to provide services for select clients such as NHK,
                    Coca Cola, SoftBank, and DAZN.{" "}
                  </p>
                </>
              }
            />
          </div>
        </div>
      </section>

      <section id="location" className="location intersection">
        <div className="section__header__wrapper">
          <Fade bottom distance={"20px"} duration={400} delay={300}>
            <h2 className="section__header">LOCATION</h2>
          </Fade>
        </div>
        <div className="location__content">
          <Location />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
